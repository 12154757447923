import React, { FC, useEffect } from 'react'
import { AppBar, Box, CircularProgress, Dialog, IconButton, LinearProgress, Paper, Toolbar } from '@mui/material'
import { oracleApi } from '../../../store/oracle/api'
import { OracleBybitAsset, OracleCapitalAsset, OracleExchange } from '../../../types/models/oracle'
import { Panels } from '../../../common/panels/panels'
import { MultipleChart } from '../chart/multipleChart'
import { useAppDispatch } from '../../../store/store'
import { removeChart, setChartDataSource, useChartState } from '../../../store/chart/slice'
import { ChartToolBar } from '../chart/chartToolBar'
import { H3, P2 } from '../../../common/typography'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import { loadChartData } from '../../../store/chart/actions'
import { ChartDataSource } from '../../../types/models/chart'

interface Props {
  onClose: () => void
  symbol: string | null
  rows?: OracleCapitalAsset[] | OracleBybitAsset[]
  exchange: OracleExchange,
}

export const AnalyticsDialog: FC<Props> = ({ onClose, symbol, rows, exchange }) => {
  const dispatch = useAppDispatch()
  const { loading: chartLoading } = useChartState()
  const {
    data,
    isFetching: analyticsLoading,
    refetch
  } = oracleApi.useGetCapitalAnalyticsQuery({ exchange, symbol: symbol as string }, { skip: !symbol })


  useEffect(() => {
    if (symbol) {
      dispatch(setChartDataSource(ChartDataSource.ORACLE))
      dispatch(loadChartData({ symbol, selection: 'single', oracleExchange: exchange }))
    }
    return () => {
      dispatch(setChartDataSource(ChartDataSource.CURRENCY))
      dispatch(removeChart(symbol!))
    }
  }, [symbol])

  const handleRefresh = () => {
    if (symbol) {
      refetch()
      dispatch(loadChartData({ symbol, selection: 'single', oracleExchange: exchange }))
    }
  }

  const assetName = symbol ? rows?.find(row => row.symbol === symbol)?.name || '' : ''

  return (
    <Dialog open={!!symbol} onClose={onClose} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton onClick={onClose}>
            <CloseIcon/>
          </IconButton>
          <IconButton onClick={handleRefresh}>
            <RefreshIcon/>
          </IconButton>
          <P2 sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Analytics
          </P2>
        </Toolbar>
      </AppBar>
      {(chartLoading || analyticsLoading) && <LinearProgress/>}
      <Panels autoSaveId="oracle-asset-analytics">
        <Paper sx={{ height: '100%', p: 2 }} variant="outlined">

          <H3 mb="20px">{assetName}</H3>
          {
            analyticsLoading
              ? <Box display="flex" justifyContent="center"><CircularProgress/></Box>
              :
              <Box display="flex" gap="40px">
                <Box display="flex" flexDirection="column" gap="10px" width="max-content">
                  <P2 untranslate width="max-content"><b>Stop loss:</b> {data?.stop_loss}</P2>
                  <P2 untranslate width="max-content"><b>Take profit:</b> {data?.take_profit}</P2>
                  <P2 untranslate width="max-content"><b>Side:</b> {data?.order_side}</P2>
                  <P2 untranslate width="max-content"><b>Action:</b> {JSON.stringify(data?.action)?.toUpperCase()}</P2>
                </Box>
                <P2>{data?.reasoning}</P2>
              </Box>

          }

        </Paper>

        <Paper sx={{ height: '100%' }} variant="outlined">
          <MultipleChart toolbar={<ChartToolBar/>}/>
        </Paper>

      </Panels>
    </Dialog>
  )
}
