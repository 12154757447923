import {
  OracleAssetAnalytics,
  OracleBybitAsset,
  OracleBybitTradeSettings,
  OracleCapitalAsset,
  OracleExchange
} from '../../types/models/oracle'
import { apiRTK } from '../api'


export const oracleApi = apiRTK.injectEndpoints({
  endpoints: (build) => ({
    getCapitalAssets: build.query<OracleCapitalAsset[], unknown>({
      query: () => ({ url: 'oracle/get_capital_assets' }),
      transformResponse: (response: Record<string, Omit<OracleCapitalAsset, 'symbol'>>) => {
        return response ? Object.entries(response).map(([key, value]) => ({ symbol: key, ...value })) : []
      }
    }),
    getBybitAssets: build.query<OracleBybitAsset[], unknown>({
      query: () => ({ url: 'oracle/get_bybit_assets' }),
      transformResponse: (response: Record<string, Omit<OracleBybitAsset, 'symbol'>>) => {
        return response ? Object.entries(response).map(([key, value]) => ({ symbol: key, ...value })) : []
      }
    }),
    editPreferences: build.mutation<any, {
      symbol: string,
      exchange: OracleExchange,
      risk?: number,
      time_delta?: number,
      price_delta?: number,
      price_delta_reverse?: number,
      trend_direction?: string
    }>({
      query: ({ exchange, symbol, risk, price_delta, price_delta_reverse, time_delta, trend_direction }) => ({
        url: 'oracle/save_preferences',
        method: 'POST',
        body: { exchange, symbol, risk, price_delta, price_delta_reverse, time_delta, trend_direction }
      })
    }),
    getCapitalAnalytics: build.query<OracleAssetAnalytics, { symbol: string, exchange: OracleExchange, }>({
      query: ({ symbol, exchange }) => ({
        url: 'oracle/analyze_asset',
        method: 'POST',
        params: { epic: symbol, exchange }
      })
    }),
    getBybitTradeSettings: build.query<OracleBybitTradeSettings[], unknown>({
      query: () => ({
        url: 'oracle/get_bybit_trade_settings'
      })
    }),
    editBybitTradeSettings: build.mutation<any, OracleBybitTradeSettings>({
      query: (body) => ({
        url: 'oracle/save_bybit_trade_settings',
        method: 'POST',
        body
      })
    })
  })
})

