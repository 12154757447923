import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useBotState } from '../../../store/bot/slice'
import { useNotify } from '../../../common/notify/useNotify'
import { useTranslation } from 'react-i18next'
import { BotChart } from '../chart/botChart'
import { Box, CircularProgress } from '@mui/material'
import { useChartState } from '../../../store/chart/slice'
import { P1 } from '../../../common/typography'
import { useAppDispatch } from '../../../store/store'
import { loadChartData } from '../../../store/chart/actions'
import { loadBotMarkers } from '../../../store/bot/actions'

export const BotChartPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { botId } = useParams()
  const navigate = useNavigate()
  const { snackbar } = useNotify()
  const { bots, loading: botLoading, markers } = useBotState()
  const bot = bots.find(b => b.id === Number(botId))
  const { chartsData, loading: chartLoading } = useChartState()
  const chartData = chartsData.find(c => c.symbol === bot?.symbol)


  useEffect(() => {
    if (!bot && !botLoading) {
      snackbar(t('Bot not found'))
      navigate('/trading/bots')
    }
  }, [botId, navigate, bot, botLoading, snackbar])

  useEffect(() => {
    if (bot) {
      dispatch(loadChartData({ symbol: bot.symbol, selection: 'single', interval: bot.interval }))
      dispatch(loadBotMarkers(bot.id))
    }
  }, [bot, dispatch])

  if (botLoading || chartLoading) return <Box mx="auto" mt={3}><CircularProgress/></Box>
  if (!bot || !chartData) return <P1 mt={3} mx="auto">Bot not found {bot?.symbol}</P1>
  return (
    <Box width="100%" height="100%">
      <BotChart bot={bot} chartData={chartData} markers={markers}/>
    </Box>
  )
}
