import { FC, SyntheticEvent } from 'react'
import { Tab, Tabs } from '@mui/material'
import { P2 } from '../../../common/typography'
import { SettingsTab } from '../../../types/models/common'

interface SettingsTabsProps {
  currentTab: SettingsTab
  onChange: (tab: SettingsTab) => void
}

export const SettingsTabs: FC<SettingsTabsProps> = ({ currentTab, onChange }) => {

  const handleChangeTab = (_: SyntheticEvent, newValue: SettingsTab) => {
    onChange(newValue)
  }
  const tabs: { tab: SettingsTab, label: string }[] = [
    { tab: SettingsTab.APP, label: 'App' },
    // { tab: SettingsTab.PLATFORM, label: 'Platforms' },
    { tab: SettingsTab.SUBSCRIPTION, label: 'Subscription' }
  ]
  return (
    <Tabs
      value={currentTab}
      orientation="vertical"
      onChange={handleChangeTab}
    >
      {tabs.map(({ tab, label }) => (
        <Tab
          key={tab}
          sx={{ alignItems: 'start' }}
          value={tab}
          label={<P2 variant="caption" ml={1}>{label}</P2>}
        />
      ))}
    </Tabs>
  )
}
