import { FC, useMemo, useState } from 'react'
import { DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Box, IconButton, MenuItem, Paper } from '@mui/material'
import { oracleApi } from '../../../store/oracle/api'
import { RiskSlider } from './RiskSlider'
import {
  OracleAssetTrandDirection,
  OracleCapitalAssetStatus,
  OracleCapitalAssetType,
  OracleExchange
} from '../../../types/models/oracle'
import { useSnackbar } from 'notistack'
import { AnalyticsDialog } from './analyticsDialog'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import { KitInput } from '../../../common/ui-kit/inputs/kitInput'
import './styles.css'

interface Filters {
  type?: string
  status?: string
  riskFrom?: number
  riskTo?: number
}

export const OracleCapitalTablePage: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0 })
  const [analyticsDialogSymbol, setAnalyticsDialogSymbol] = useState<null | string>(null)
  const [filters, setFilters] = useState<Filters>({})

  const { data: rows, isFetching } = oracleApi.useGetCapitalAssetsQuery({})
  const [editPreferences, { isLoading: editRiskLoading }] = oracleApi.useEditPreferencesMutation()

  const filteredRows = useMemo(() => {
    if (!rows) return []
    return rows
      .filter(row => filters.type ? row.type === filters.type : row)
      .filter(row => filters.status ? row.status === filters.status : row)
      .filter(row => filters.riskFrom ? row.risk >= filters.riskFrom : row)
      .filter(row => filters.riskTo ? row.risk <= filters.riskTo : row)
  }, [filters, rows])

  const handleFiltersChange = (filters: Filters) => {
    setFilters(prev => ({ ...prev, ...filters }))
  }

  const handleProcessRowUpdate = async (newRow: any) => {
    const body = {
      exchange: OracleExchange.CAPITAL,
      symbol: newRow.symbol,
      risk: newRow.risk,
      time_delta: newRow.time_delta,
      price_delta: newRow.price_delta,
      price_delta_reverse: newRow.price_delta_reverse,
      trend_direction: newRow.trend_direction
    }
    await editPreferences(body).unwrap()
    return newRow
  }


  const columns: GridColDef[] = [
    { field: 'name', width: 150, headerName: 'Market' },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(OracleCapitalAssetType),
      width: 120
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(OracleCapitalAssetStatus),
      width: 120
    },
    { field: 'bid', width: 120, type: 'number', headerName: 'Price' },
    { field: 'short', type: 'boolean', headerName: 'Short' },
    { field: 'long', type: 'boolean', headerName: 'Long' },
    {
      field: 'risk_ai',
      type: 'number',
      headerName: 'Risk AI',
      headerAlign: 'center',
      width: 200,
      display: 'flex',
      align: 'center',
      renderCell: (params) => (<RiskSlider readonly  {...params}/>)
    },
    {
      field: 'risk',
      type: 'number',
      headerName: 'Risk',
      headerAlign: 'center',
      editable: true,
      width: 200,
      display: 'flex',
      align: 'center',
      renderCell: (params) => (<RiskSlider readonly  {...params}/>),
      renderEditCell: (params) => (<RiskSlider loading={editRiskLoading} {...params}/>)
    },
    { field: 'time_delta', headerName: 'Time delta', align: 'center', type: 'number', editable: true, width: 100 },
    { field: 'price_delta', headerName: 'Price delta', align: 'center', type: 'number', editable: true, width: 100 },
    {
      field: 'price_delta_reverse',
      headerName: 'Price delta reverse',
      align: 'center',
      type: 'number',
      editable: true,
      width: 160
    },
    {
      field: 'trend_direction',
      headerName: 'Trend direction',
      align: 'center',
      type: 'singleSelect',
      valueOptions: Object.values(OracleAssetTrandDirection),
      editable: true,
      width: 130
    },

    {
      field: 'symbol',
      headerName: '',
      renderCell: ({ value }) => <IconButton
        onClick={() => setAnalyticsDialogSymbol(value)}><AnalyticsIcon/></IconButton>,
      width: 50
    }
  ]


  const getToolbar = () => (
    <Box py={1} px={2} display="flex" gap="10px" alignItems="end">
      <GridToolbarQuickFilter sx={{ maxWidth: '150px', p: 0, height: '30px' }}/>
      <KitInput
        label="Type"
        select
        sx={{ maxWidth: '200px' }}
        value={filters.type}
        onChange={e => handleFiltersChange({ type: e.target.value })}
      >
        <MenuItem value={undefined}>All</MenuItem>
        {Object.values(OracleCapitalAssetType).map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
      </KitInput>

      <KitInput
        label="Status"
        select
        sx={{ maxWidth: '150px' }}
        value={filters.status}
        onChange={e => handleFiltersChange({ status: e.target.value })}
      >
        <MenuItem value={undefined}>All</MenuItem>
        {Object.values(OracleCapitalAssetStatus).map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
      </KitInput>

      <KitInput
        label="Risk from"
        select
        sx={{ maxWidth: '120px' }}
        value={filters.riskFrom}
        onChange={e => handleFiltersChange({ riskFrom: e.target.value ? Number(e.target.value) : undefined })}
      >
        <MenuItem value={undefined}>All</MenuItem>
        {new Array(10).fill(() => '').map((_, index) => <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>)}
      </KitInput>

      <KitInput
        label="Risk to"
        select
        sx={{ maxWidth: '100px' }}
        value={filters.riskTo}
        onChange={e => handleFiltersChange({ riskTo: e.target.value ? Number(e.target.value) : undefined })}
      >
        <MenuItem value={undefined}>All</MenuItem>
        {new Array(10).fill(() => '').map((_, index) => <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>)}
      </KitInput>
    </Box>
  )


  return (
    <Paper variant="outlined" sx={{ width: '100%' }}>
      <Box height="98vh">
        <DataGrid
          sx={{ border: 'none' }}
          rows={filteredRows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: getToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
          density="standard"
          disableColumnMenu
          disableRowSelectionOnClick
          loading={isFetching}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={() => enqueueSnackbar('Risk has not been changed', { variant: 'error' })}
          getRowId={(row) => row.symbol}
          getCellClassName={params => params.isEditable ? 'oracle-table-cell-editable' : ''}
        />
      </Box>
      <AnalyticsDialog
        onClose={() => setAnalyticsDialogSymbol(null)}
        symbol={analyticsDialogSymbol}
        rows={rows}
        exchange={OracleExchange.CAPITAL}
      />
    </Paper>

  )
}
