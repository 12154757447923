import { FC, useEffect, useMemo } from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid2,
  Paper,
  Stack
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { OracleBybitTradeSettings } from '../../../types/models/oracle'
import { KitInput } from '../../../common/ui-kit/inputs/kitInput'
import styled from '@emotion/styled'
import { H3 } from '../../../common/typography'
import { useSnackbar } from 'notistack'
import { oracleApi } from '../../../store/oracle/api'

interface Props {
  onClose: () => void
  symbol: string | null
  settings?: OracleBybitTradeSettings[]
  onSettingsChange: (settings: OracleBybitTradeSettings) => void
  loading: boolean
}

export const OracleBybitTableSettingsDialog: FC<Props> = ({ loading, symbol, onClose, settings, onSettingsChange }) => {
  const { enqueueSnackbar } = useSnackbar()

  const currentSettings = useMemo(() => settings?.find(s => s.symbol === symbol) || {}, [settings, symbol])

  const { register, handleSubmit, reset, control } = useForm<OracleBybitTradeSettings>({
    defaultValues: {
      symbol: symbol || '',
      ...currentSettings
    },
    mode: 'onChange'
  })

  const [editSettingsMutation, { isLoading }] = oracleApi.useEditBybitTradeSettingsMutation()


  useEffect(() => {
    reset({ ...currentSettings })
  }, [currentSettings, reset])


  const onSubmit = (data: any) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value === '') {
        data[key] = null
      }
    })
    editSettingsMutation({...data, symbol}).unwrap()
      .then(() => {
        onClose()
        onSettingsChange(data)
        enqueueSnackbar('Settings successfully updated', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Settings have not been updated', { variant: 'error' })
      })


  }

  return (
    <Dialog open={!!symbol} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledForm variant="outlined">
          <Backdrop open={loading || isLoading}><CircularProgress color="inherit"/></Backdrop>
          <Stack direction="row" spacing={2} alignItems="center" mb={2}>
            <SettingsOutlinedIcon fontSize="large"/>
            <H3>{symbol}</H3>
          </Stack>
          <Grid2 container spacing={2}>
            <Grid2 size={6}>
              <KitInput label="BID" type="number" {...register('bid', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Leverage" type="number" {...register('leverage', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Take profit" type="number" {...register('take_profit', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Stop loss" type="number" {...register('stop_loss', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Delta" type="number" {...register('delta', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Delta time" type="number" {...register('delta_time', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Delta between" type="number" {...register('delta_between', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Delta time between"
                        type="number" {...register('delta_time_between', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Take profit trigger" type="number" {...register('tp_trigger', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Max BID" type="number" {...register('max_bid', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Cancel distance"
                        type="number" {...register('cancel_distance', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Pre pump" type="number" {...register('pre_pump', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Retracement" type="number" {...register('retracement', { valueAsNumber: true })}/>
            </Grid2>

            <Grid2 size={6}>
              <KitInput label="Hedge limit" type="number" {...register('hedge_limit', { valueAsNumber: true })}/>
            </Grid2>

            <FormControlLabel
              control={
                <Controller
                  name={'buy'}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox{...props} checked={props.value} onChange={(e) => props.onChange(e.target.checked)}/>
                  )}
                />
              }
              label={'BUY'}
            />
            <FormControlLabel
              control={
                <Controller
                  name={'sell'}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox{...props} checked={props.value} onChange={(e) => props.onChange(e.target.checked)}/>
                  )}
                />
              }
              label={'SELL'}
            />
          </Grid2>

          <Stack direction="row" justifyContent="space-between" mt={3}>
            <Button color="inherit" type="button" onClick={onClose}>Cancel</Button>
            <Button type="submit">Save</Button>
          </Stack>

        </StyledForm>
      </form>

    </Dialog>
  )
}

const StyledForm = styled(Paper)`
    padding: 20px;
`

