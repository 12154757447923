export interface OracleCapitalAsset {
  symbol: string
  bid: number
  direction: number
  long: boolean
  name: string
  short: boolean
  status: OracleCapitalAssetStatus,
  type: OracleCapitalAssetType
  risk: number,
  risk_ai: number,
  time_delta: number,
  price_delta: number,
  price_delta_reverse: number,
  trend_direction: OracleAssetTrandDirection
}

export interface OracleBybitAsset {
  symbol: string
  direction: number
  name: string
  opened_positions: boolean
  price_delta: number
  price_delta_reverse: number
  risk: number
  risk_ai: number
  time_delta: number
  trend_direction: OracleAssetTrandDirection
  'metrics': {
    color?: string

    'last_minute_delta': number
    'last_5_minutes_delta': OracleBybitAssetMetric
    'last_15_minutes_delta': OracleBybitAssetMetric
    'last_hour_delta': OracleBybitAssetMetric
    'last_6_hours_delta': number

    'last_minute_volatility': number
    'last_5_minutes_volatility': OracleBybitAssetMetric
    'last_15_minutes_volatility': OracleBybitAssetMetric
    'last_hour_volatility': OracleBybitAssetMetric
    'last_6_hours_volatility': number

    'last_15_minutes_volume_percentage': number
    'last_5_minutes_volume_percentage': number
    'last_hour_volume_percentage': number
  },
}


export enum OracleCapitalAssetStatus {
  TRADEABLE = 'TRADEABLE',
  CLOSED = 'CLOSED',
}

export enum OracleCapitalAssetType {
  SHARES = 'SHARES',
  CRYPTOCURRENCIES = 'CRYPTOCURRENCIES',
  COMMODITIES = 'COMMODITIES',
  INDICES = 'INDICES',
  CURRENCIES = 'CURRENCIES',
  UNKNOWN = 'UNKNOWN'
}

export enum OracleAssetTrandDirection {
  LONG = 'LONG',
  SHORT = 'SHORT',
  SIDE = 'SIDE'
}

export enum OracleExchange {
  CAPITAL = 'capital',
  BYBIT = 'bybit'
}


export interface OracleAssetAnalytics {
  action: boolean
  order_side: 'Buy' | string
  reasoning: string
  stop_loss: number
  take_profit: number
}


export type OracleBybitAssetMetric = [number | null, number | null]

export interface OracleBybitTradeSettings {
  id: number
  user_id: number
  symbol: string
  bid?: number
  leverage?: number
  buy?: boolean
  sell?: boolean
  take_profit?: number
  stop_loss?: number
  delta?: number
  delta_time?: number
  delta_between?: number
  delta_time_between?: number
  tp_trigger?: number
  max_bid?: number
  cancel_distance?: number
  pre_pump?: number
  retracement?: number
  hedge_limit?: number
}
