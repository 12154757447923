import { Navigate, useLocation } from 'react-router-dom'
import { FC } from 'react'
import { AuthLayout } from './auth/authLayout'
import { LoginPage } from './auth/loginPage'
import { SignupPage } from './auth/signupPage'
import { UserPage } from './user/userPage'
import { EditUserPage } from './user/editUserPage'
import { UserLayout } from './user/userLayout'
import { RouteObjectWithProtected, useRoutesWithProtected } from '../hooks/useRoutesWithProtected'
import { useAuthState } from '../store/auth/slice'
import { Box, LinearProgress } from '@mui/material'
import { TradingLayout } from './trading/tradingLayout'
import { AnalyticsPage } from './trading/analyticsPage'
import { PredictionPage } from './trading/predictionPage'
import { BotsPage } from './trading/bot/botsPage'
import { BotChartPage } from './trading/bot/botChartPage'
import { NewsPage } from './news/newsPage'
import { NewsLayout } from './news/newsLayout'
import { SubscriptionPage } from './subscription/subscriptionPage'
import { CookiesPolicyPage } from './info/CookiesPolicyPage'
import { OracleCapitalTablePage } from './trading/oracleTable/oracleCapitalTablePage'
import { OracleBybitTablePage } from './trading/oracleTable/oracleBybitTablePage'

export const AppRoutes: FC = () => {
  const location = useLocation()
  const { isAuth, loading } = useAuthState()

  const routes: RouteObjectWithProtected[] = [
    {
      path: '/news/:categoryName?', element: <NewsLayout/>, children: [
        { path: '', element: <NewsPage/> }
      ]
    },
    {
      path: 'auth', element: <AuthLayout/>,
      protected: { navigate: location.state?.referrer?.pathname || '/', accessibility: !isAuth },
      children: [
        { path: '', element: <Navigate to="/auth/login"/> },
        { path: 'signup', element: <SignupPage/> },
        { path: 'login', element: <LoginPage/> },
        { path: '*', element: <Navigate to="/auth/login"/> }
      ]
    },
    {
      path: 'user/:userId', element: <UserLayout/>,
      protected: { navigate: '/auth/login', accessibility: isAuth },
      children: [
        { path: '', element: <UserPage/> },
        { path: 'edit', element: <EditUserPage/> },
        { path: '*', element: <Navigate to=".."/> }
      ]
    },
    {
      path: 'subscription', element: <SubscriptionPage/>
    },
    {
      path: '/trading', element: <TradingLayout/>,
      protected: { navigate: '/auth/login', accessibility: isAuth },
      children: [
        { path: '', element: <Navigate to="/trading/oracle/capital"/> },
        { path: 'analytics', element: <AnalyticsPage/> },
        { path: 'prediction', element: <PredictionPage/> },
        { path: 'bots', element: <BotsPage/> },
        { path: 'bots/:botId', element: <BotChartPage/> },
        {path: 'oracle/capital', element: <OracleCapitalTablePage/>},
        {path: 'oracle/bybit', element: <OracleBybitTablePage/>},
        { path: 'bots/:botId/*', element: <Navigate to="/trading/bots"/> },
        { path: '*', element: <Navigate to=".."/> }
      ]
    },
    { path: 'info/cookies_policy', element: <CookiesPolicyPage/> },
    { path: '*', element: <Navigate to="/news"/> }
  ]

  const elements = useRoutesWithProtected(routes)
  return (
    <>
      {
        loading
          ? <LinearProgress/>
          : <Box mt={1}>{elements}</Box>
      }
    </>
  )
}
