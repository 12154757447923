import { FC, useState } from 'react'
import { Box, Dialog, Paper } from '@mui/material'
import { SettingsTabs } from './settingsTabs'
import { P1 } from '../../../common/typography'
import { SettingsTab } from '../../../types/models/common'
import { AppSettings } from './tabs/appSettings'
import { SubscriptionSettings } from './tabs/subscriptionSettings'
import { KitButton } from '../../../common/ui-kit/kitButton'
import { useAppDispatch } from '../../../store/store'
import { authActions } from '../../../store/auth/slice'


interface SettingsDialogProps {
  open: boolean
  onClose: () => void
}


export const SettingsDialog: FC<SettingsDialogProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch()
  const [currentTab, setCurrentTab] = useState<SettingsTab>(SettingsTab.APP)

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Paper sx={{ p: 3, minHeight: '50vh' }}>
        <Box className="border-b" mb={2} display="flex">
          <P1>Settings</P1>
        </Box>
        <Box display="flex" minHeight="40vh">
          <Box mr={2} display="flex" flexDirection="column" justifyContent="space-between">
            <SettingsTabs currentTab={currentTab} onChange={setCurrentTab}/>
            <KitButton variant="outlined" onClick={() => dispatch(authActions.logout())}>Logout</KitButton>
          </Box>
          {currentTab === SettingsTab.APP && <AppSettings/>}
          {/*{currentTab === SettingsTab.PLATFORM && <PlatformSettings/>}*/}
          {currentTab === SettingsTab.SUBSCRIPTION && <SubscriptionSettings/>}
        </Box>
      </Paper>
    </Dialog>
  )
}
