import { FC, useRef } from 'react'
import { Box, CircularProgress, Slider } from '@mui/material'
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid'
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils'

interface Props extends GridRenderCellParams<any, number> {
  readonly?: boolean
  loading?: boolean
}

export const RiskSlider: FC<Props> = ({ readonly, loading, id, value, field, hasFocus }) => {
  const apiRef = useGridApiContext()
  const ref = useRef<HTMLElement>(null)

  const handleChange = (_: Event, newValue: number | number[]) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue })
  }

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(
        `input[value="${value}"]`
      )
      input?.focus()
    }
  }, [hasFocus, value])

  const getThumbColor = () => {
    if (!value) return '#59b647'
    if (value <= 5) {
      const greenToYellow = value / 5
      const r = Math.round(133 + (255 - 133) * greenToYellow)
      const g = Math.round(200 + (217 - 200) * greenToYellow)
      const b = Math.round(138 + (102 - 138) * greenToYellow)
      return `rgb(${r}, ${g}, ${b})`
    } else {
      const yellowToRed = (value - 5) / 5
      const r = Math.round(255 - (255 - 242) * yellowToRed)
      const g = Math.round(217 - (217 - 139) * yellowToRed)
      const b = Math.round(102 - (102 - 130) * yellowToRed)
      return `rgb(${r}, ${g}, ${b})`
    }
  }


  const marks = new Array(10).fill(() => '').map((_, i) => ({ value: i + 1, label: i + 1 }))
  if (!value && readonly) return <></>
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="180px" sx={{ scale: '1' }}>
      {loading ? <CircularProgress/> : <Slider
        value={value || 5}
        onChange={handleChange}
        valueLabelDisplay="off"
        step={1}
        marks={marks}
        min={1}
        max={10}
        disabled={readonly}
        sx={{
          '& .MuiSlider-thumb': {
            width: 20,
            height: 20,
            backgroundColor: getThumbColor()
          }
        }}
      />}

    </Box>
  )
}
