import { FC, useEffect, useState } from 'react'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridRow,
  GridRowProps,
  GridSortModel,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import { Box, Button, CircularProgress, IconButton, Paper } from '@mui/material'
import { oracleApi } from '../../../store/oracle/api'
import { useSnackbar } from 'notistack'
import './styles.css'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { P2, P3 } from '../../../common/typography'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import CachedIcon from '@mui/icons-material/Cached'
import useLocalStorageState from 'use-local-storage-state'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { OracleBybitTableSettingsDialog } from './oracleBybitTableSettingsDialog'
import { OracleBybitTradeSettings } from '../../../types/models/oracle'


export const OracleBybitTablePage: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0 })
  const [sortModel, setSortModel] = useLocalStorageState<GridSortModel>('oracle-bybit-table_sort-model', { defaultValue: [] })
  const [settingsDialogSymbol, setSettingsDialogSymbol] = useState<string | null>(null)

  const {
    data: rows,
    isLoading: assetsIsLoading,
    fulfilledTimeStamp,
    refetch,
    isFetching: assetsIsFetching,
    isError
  } = oracleApi.useGetBybitAssetsQuery({}, { pollingInterval: 30000 })
  let { data: tradeSettingsData, isFetching: tradeSettingsIsLoading } = oracleApi.useGetBybitTradeSettingsQuery({})

  const [tradeSettings, setTradeSettings] = useState(tradeSettingsData)

  useEffect(() => {
    setTradeSettings(tradeSettingsData)
  }, [tradeSettingsData])


  function metricsValueGetter(this: GridColDef, _: any, row: any) {
    const key = this.field
    const value = row?.metrics[key.replace('_first', '').replace('_second', '')]
    if (!value) return 0
    if (key.endsWith('_first')) {
      return value[0]
    } else if (key.endsWith('_second')) {
      return value[1]
    }
    return value
  }

  const handleSettingsChange = (settings: OracleBybitTradeSettings) => {
    if (tradeSettings) {
      setTradeSettings(tradeSettings.map(s => s.symbol === settings.symbol ? settings : s))
    } else {
      setTradeSettings([settings])
    }
  }

  const columnDefaultValues: Partial<GridColDef> = {
    width: 75,
    valueGetter: metricsValueGetter,
    headerAlign: 'center',
    headerName: ''
  }
  const columns: GridColDef[] = [
    {
      field: 'name', width: 210, headerName: 'Market', renderCell: ({ value }) => (
        <NavLink to={`https://www.bybit.com/trade/usdt/${value}USDT`} target="_blank">
          <Button
            variant="text"
            fullWidth
            sx={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'space-between' }}
            color="secondary"
          >
            <P2 fontSize="15px">{value}</P2> <OpenInNewIcon sx={{ fontSize: '15px' }}/>
          </Button>
        </NavLink>
      )
    },
    { field: 'last_minute_delta', ...columnDefaultValues },
    { field: 'last_5_minutes_delta_first', ...columnDefaultValues },
    { field: 'last_5_minutes_delta_second', ...columnDefaultValues },
    { field: 'last_15_minutes_delta_first', ...columnDefaultValues },
    { field: 'last_15_minutes_delta_second', ...columnDefaultValues },
    { field: 'last_hour_delta_first', ...columnDefaultValues },
    { field: 'last_hour_delta_second', ...columnDefaultValues },
    { field: 'last_6_hours_delta', ...columnDefaultValues },

    { field: 'last_5_minutes_volume_percentage', ...columnDefaultValues },
    { field: 'last_15_minutes_volume_percentage', ...columnDefaultValues },
    { field: 'last_hour_volume_percentage', ...columnDefaultValues },

    { field: 'last_minute_volatility', ...columnDefaultValues },
    { field: 'last_5_minutes_volatility_first', ...columnDefaultValues },
    { field: 'last_5_minutes_volatility_second', ...columnDefaultValues },
    { field: 'last_15_minutes_volatility_first', ...columnDefaultValues },
    { field: 'last_15_minutes_volatility_second', ...columnDefaultValues },
    { field: 'last_hour_volatility_first', ...columnDefaultValues },
    { field: 'last_hour_volatility_second', ...columnDefaultValues },
    { field: 'last_6_hours_volatility', ...columnDefaultValues },

    {
      field: 'action',
      headerName: '',
      renderCell: ({ row }) =>
        <IconButton onClick={() => setSettingsDialogSymbol(row.name)} disabled={tradeSettingsIsLoading}>
          <SettingsOutlinedIcon/>
        </IconButton>,
      width: 50
    }

  ]

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Volume percentage',
      headerAlign: 'center',
      children: [
        {
          groupId: '5m',
          headerAlign: 'center',
          children: [{ field: 'last_5_minutes_volume_percentage' }]
        },
        {
          groupId: '15m',
          headerAlign: 'center',
          children: [{ field: 'last_15_minutes_volume_percentage' }]
        },
        {
          groupId: '1h',
          headerAlign: 'center',
          children: [{ field: 'last_hour_volume_percentage' }]
        },
      ]
    },
    {
      groupId: 'Delta price',
      headerAlign: 'center',
      children: [
        {
          groupId: '1m',
          headerAlign: 'center',
          children: [{ field: 'last_minute_delta' }]
        },
        {
          groupId: '5m',
          headerAlign: 'center',
          children: [{ field: 'last_5_minutes_delta_first' }, { field: 'last_5_minutes_delta_second' }]
        },
        {
          groupId: '15m',
          headerAlign: 'center',
          children: [{ field: 'last_15_minutes_delta_first' }, { field: 'last_15_minutes_delta_second' }]
        },
        {
          groupId: '1h',
          headerAlign: 'center',
          children: [{ field: 'last_hour_delta_first' }, { field: 'last_hour_delta_second' }]
        },
        {
          groupId: '6h',
          headerAlign: 'center',
          children: [{ field: 'last_6_hours_delta' }]
        }
      ]
    },
    {
      groupId: 'Volatility',
      headerAlign: 'center',
      children: [
        {
          groupId: '1m',
          headerAlign: 'center',
          children: [{ field: 'last_minute_volatility' }]
        },
        {
          groupId: '5m',
          headerAlign: 'center',
          children: [{ field: 'last_5_minutes_volatility_first' }, { field: 'last_5_minutes_volatility_second' }]
        },
        {
          groupId: '15m',
          headerAlign: 'center',
          children: [{ field: 'last_15_minutes_volatility_first' }, { field: 'last_15_minutes_volatility_second' }]
        },
        {
          groupId: '1h',
          headerAlign: 'center',
          children: [{ field: 'last_hour_volatility_first' }, { field: 'last_hour_volatility_second' }]
        },
        {
          groupId: '6h',
          headerAlign: 'center',
          children: [{ field: 'last_6_hours_volatility' }]
        }
      ]
    },


  ]


  const getToolbar = () => (
    <Box py={1} px={2} display="flex" gap="10px" alignItems="end">
      <GridToolbarQuickFilter sx={{ maxWidth: '150px', p: 0, height: '30px' }}/>
      <Button variant="text" color={isError && !fulfilledTimeStamp ? 'error' : 'secondary'} onClick={refetch}
              sx={{ width: '140px' }}>
        {assetsIsFetching ? <CircularProgress size={24}/> : <CachedIcon/>}
        <P3 ml="10px" fontSize="13px" untranslate>
          {isError && !fulfilledTimeStamp && <b>sync error</b>}
          {!isError && !fulfilledTimeStamp && ''}
          {fulfilledTimeStamp && !isError && new Date(fulfilledTimeStamp || 0).toLocaleTimeString()}
        </P3>
      </Button>

      {/*<KitInput*/}
      {/*  label="Risk from"*/}
      {/*  select*/}
      {/*  sx={{ maxWidth: '120px' }}*/}
      {/*  value={filters.riskFrom}*/}
      {/*  onChange={e => handleFiltersChange({ riskFrom: e.target.value ? Number(e.target.value) : undefined })}*/}
      {/*>*/}
      {/*  <MenuItem value={undefined}>All</MenuItem>*/}
      {/*  {new Array(10).fill(() => '').map((_, index) => <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>)}*/}
      {/*</KitInput>*/}

      {/*<KitInput*/}
      {/*  label="Risk to"*/}
      {/*  select*/}
      {/*  sx={{ maxWidth: '100px' }}*/}
      {/*  value={filters.riskTo}*/}
      {/*  onChange={e => handleFiltersChange({ riskTo: e.target.value ? Number(e.target.value) : undefined })}*/}
      {/*>*/}
      {/*  <MenuItem value={undefined}>All</MenuItem>*/}
      {/*  {new Array(10).fill(() => '').map((_, index) => <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>)}*/}
      {/*</KitInput>*/}
    </Box>
  )

  const getCellClassName = (params: GridCellParams) => {
    const classes = []
    if (params.isEditable) classes.push('oracle-table-cell-editable')
    if (params.field.includes('last_')) classes.push('metric-cell')
    if (params.field.includes('last_') && !params.field.includes('_second')) classes.push('cell-border-left')
    return classes.join(' ')
  }

  const CustomGridRow = (props: GridRowProps) => (
    <GridRow
      style={{ background: props.row.color ? props.row.color : undefined }}
      {...props}
    />
  )
  return (
    <Paper variant="outlined" sx={{ width: 'calc(100% - 127px)' }}>
      <StyledWrapper>
        <DataGrid
          sx={{ border: 'none' }}
          rows={rows}
          columns={columns}
          columnGroupingModel={columnGroupingModel}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: getToolbar, row: CustomGridRow }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
          density="compact"
          disableColumnMenu
          disableRowSelectionOnClick
          loading={assetsIsLoading}
          onProcessRowUpdateError={() => enqueueSnackbar('Risk has not been changed', { variant: 'error' })}
          getRowId={(row) => row.symbol}
          getCellClassName={getCellClassName}
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          // processRowUpdate={handleProcessRowUpdate}
        />
      </StyledWrapper>
      <OracleBybitTableSettingsDialog
        onClose={() => setSettingsDialogSymbol(null)}
        symbol={settingsDialogSymbol}
        loading={tradeSettingsIsLoading}
        settings={tradeSettings}
        onSettingsChange={handleSettingsChange}
      />
    </Paper>

  )
}

const StyledWrapper = styled.div`
    height: 98vh;

    .metric-cell {
        font-size: 13px;
        text-align: center;
        border-left: 1px solid var(--main-bg);
        border-right: 1px solid var(--main-bg);
    }

    .cell-border-left {
        border-left: 4px solid var(--main-bg);
    }
`

// TODO: Добавить на странице анализа lastUpdated
// TODO: сохранять данные формы в бд и отображать по 2 значение (от ai и из БД)
// TODO: добавить в форму поле reasoning
// TODO: добавить переключатель AI/MANUAL
// обновлять данные в таблице после аналитики ассета
// price_delta_value и reverse не вносить в форму
//
