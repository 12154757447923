import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { env } from '../constants/envVars'
import { localStorageService } from '../services/localStorageService'
import { authService } from '../services/authService'

export const apiRTK = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: env.API_ENDPOINT,

    prepareHeaders: async (headers) => {
      const expiresDate = localStorageService.getTokenExpiresDate() || ''
      const isExpired = new Date(expiresDate).getTime() < Date.now()
      const refreshToken = localStorageService.getRefreshToken()
      if (refreshToken && isExpired) {
        const { tokens } = await authService.refresh({ token: refreshToken })
        localStorageService.setTokens({
          refreshToken: tokens.refreshToken,
          expiresDate: tokens.expiresDate,
          accessToken: tokens.accessToken
        })
      }

      const accessToken = localStorageService.getAccessToken()
      if (!headers.get('Authorization') && accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
    }
  }),
  endpoints: () => ({})
})
