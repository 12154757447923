import { api } from './httpService'
import { Intervals } from '../constants/constants'
import { ChartData, ChartDataSource } from '../types/models/chart'
import { OracleExchange } from '../types/models/oracle'

interface OnUpdateLineData {
  ask: number,
  bid: number,
  symbol: string,
  timestamp: number
}
export const marketService = {
  getAll: async () => {
    const { data } = await api.get('/currency/markets')
    return data
  },
  getChartData: async (marketSymbol: string, interval: Intervals, source: ChartDataSource = ChartDataSource.CURRENCY, exchange?: OracleExchange): Promise<ChartData> => {
    let { data } = await api.post(`/${source}/klines`, { marketSymbol, interval, exchange })
    return { ...data, symbol: marketSymbol }
  },

  subscribeToLineMarket: (symbol: string, onUpdate: (data: OnUpdateLineData) => void): WebSocket => {
    const ws = new WebSocket('wss://api-adapter.backend.currency.com/connect')
    ws.onopen = () => {
      console.log('WS connection open')
      ws.send(JSON.stringify({
        destination: 'marketData.subscribe',
        'destination2': 'ping',
        'correlationId': 1,
        payload: { symbols: [symbol] }
      }))
    }
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data)
      switch (data?.destination) {
        case 'internal.quote':
          const formattedPayload: OnUpdateLineData = {
            ask: data.payload?.ofr,
            bid: data.payload?.bid,
            symbol: data.payload?.symbolName,
            timestamp: data.payload?.timestamp
          }
          onUpdate(formattedPayload)
          break
        default:
          console.log('UNEXPECTED WS MESSAGE', data)
          break
      }
    }

    ws.onclose = () => {
      console.log('WS connection close')
    }
    return ws
  }
}
