import { FC } from 'react'
import { useAppDispatch } from '../../../store/store'
import { Box, Chip, LinearProgress, ListItemButton, Typography } from '@mui/material'
import { useMarketState } from '../../../store/market/slice'
import { loadChartData } from '../../../store/chart/actions'
import { removeChart, useChartState } from '../../../store/chart/slice'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { useSnackbar } from 'notistack'
import { getChartColor } from '../../../utils/utils'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useTranslation } from 'react-i18next'
import { P1 } from '../../../common/typography'

interface MarketsGridProps {
  selection: 'multiple' | 'single'
}

export const MarketsGrid: FC<MarketsGridProps> = ({ selection }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { markets, loading: marketLoading } = useMarketState()
  const { chartsData, loading: chartLoading } = useChartState()
  const { enqueueSnackbar: snackbar } = useSnackbar()

  const handleRowClick = (symbol: string) => {
    if (chartsData.length >= 10) {
      return snackbar('maximum number of displayed charts 10', { variant: 'warning' })
    }
    if (!chartsData.find(chart => chart.symbol === symbol)) {
      dispatch(loadChartData({ symbol, selection }))
    } else {
      dispatch(removeChart(symbol))
    }
  }

  function renderRow({ index, style }: ListChildComponentProps) {
    const { symbol, name } = markets[index]
    const isSelected = !!chartsData.find(data => data.symbol === symbol)
    return (
      <ListItemButton style={style} key={index} onClick={() => handleRowClick(symbol)} selected={isSelected}>
        <Typography width={300}>{name}</Typography>
      </ListItemButton>
    )
  }

  return (
    <Box width="100%" >
      <Box className="border-b rounded" display="flex" height={45} overflow="auto">
        {chartsData.length === 0 &&
          <Typography color="#ccc" alignSelf="center" ml={2}>{t('Select charts')}</Typography>}
        {chartsData.map((data, index) => (
          <Chip
            key={data.symbol}
            variant="outlined"
            color="primary"
            onDelete={() => dispatch(removeChart(data.symbol))}
            label={data.symbol}
            size="small"
            sx={{ my: 'auto', ml: 1, color: getChartColor(index), fontWeight: 'bold' }}
          />
        ))}
      </Box>
      {(marketLoading || chartLoading) && <LinearProgress/>}
      {!marketLoading && !chartLoading && markets.length === 0 && <P1>No results found</P1>}
      <Box height="100%">
        <AutoSizer>
          {({ width, height }: { width: number, height: number }) => {
            return (<FixedSizeList
              height={height}
              width={width}
              itemSize={46}
              itemCount={markets.length}
              overscanCount={5}
            >
              {renderRow}
            </FixedSizeList>)
          }}
        </AutoSizer>

      </Box>
    </Box>
  )
}
