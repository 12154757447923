import { AppDispatch, GetState } from '../store'
import { addChart, setChartError, setChartLoading, setCharts } from './slice'
import { marketService } from '../../services/marketService'
import { Intervals } from '../../constants/constants'
import { OracleExchange } from '../../types/models/oracle'

const errorHandler = (error: any, dispatch: AppDispatch) => {
  console.log(error)
  dispatch(setChartError('ERROR'))
  dispatch(setChartLoading(false))
}

interface LoadChartDataParams {
  symbol: string,
  selection: 'multiple' | 'single',
  interval?: Intervals,
  oracleExchange?: OracleExchange
}

export const loadChartData = (params: LoadChartDataParams) => async (dispatch: AppDispatch, getState: GetState) => {
  const { oracleExchange, interval, symbol, selection } = params
  try {
    dispatch(setChartLoading(true))
    const { interval: stateInterval, source } = getState().chart
    const chartData = await marketService.getChartData(symbol, interval || stateInterval, source, oracleExchange)
    if (selection === 'multiple') dispatch(addChart(chartData))
    else if (selection === 'single') dispatch(setCharts([chartData]))
    dispatch(setChartLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const updateAllChartData = () => async (dispatch: AppDispatch, getState: GetState) => {
  const { interval, chartsData, source } = getState().chart
  try {
    dispatch(setChartLoading(true))
    const updatedChartsData = []
    for (const { symbol } of chartsData) {
      const updatedChart = await marketService.getChartData(symbol, interval, source)
      updatedChartsData.push(updatedChart)
    }
    dispatch(setCharts(updatedChartsData))
    dispatch(setChartLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

